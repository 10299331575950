.health section {
  position: relative;
}

.ellipse-mask {
  position: relative;
  top: 50%;
  left: -30%;
  transform: translateY(-50%);
}

.health .pin {
  height: 100vh;
  min-height: 940px;
  position: relative;
}

.healthcare {
  width: 100vw;
  height: 100vh;
  min-height: 940px;
  position: absolute;
  top: 0;
}

.healthcare img {
  visibility: hidden;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.healthcare-content {
  z-index: 3;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.healthcare-content p, .healthcare-content h1 {
  text-align: left;
}

.healthcare-content h3 {
  text-align: left;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
}

.healthcare-separator {
  width: 224px;
  height: 2px;
  background: #0f3a5733;
  margin-top: 76px;
  margin-bottom: 24px;
}

.lookbook-btn {
  margin-top: 45px;
}

@media only screen and (max-width: 350px) {
  .lookbook-btn {
    display: none !important;
    left: 30px !important;
  }

  .downloadArrowMobile {
    bottom: -80px;
    left: -10px;
  }

  #HEALTHCARE {
    background: #dbdad9e6;
  }

  .health .pin {
    flex-direction: column;
    justify-content: center;
    display: flex;
    position: relative;
    height: unset !important;
    min-height: unset !important;
  }

  .healthcare-separator {
    margin-top: unset !important;
  }

  .healthcare {
    display: none;
  }

  .healthcare-mobile-images {
    width: 100vw;
    height: 370px;
    position: relative;
  }

  .healthcare-mobile {
    width: 100vw;
    z-index: 1;
    position: absolute;
    bottom: 0;
  }

  .healthcare-mobile img {
    visibility: hidden;
    max-width: 100%;
  }

  .healthcare-mobile-images {
    display: block;
  }

  .ellipse-mask {
    display: none;
  }

  .healthcare-content {
    z-index: 2;
    background: #dbdad9e6;
    padding-top: 100px;
    padding-bottom: 60px;
    padding-right: 32px;
    position: relative !important;
  }

  .healthcare-content h1 {
    max-width: 340px;
  }

  .healthcare-content h3 {
    max-width: 390px;
  }

  .healthcare-content p {
    max-width: 410px;
  }
}

@media only screen and (min-width: 351px) and (max-width: 575px) {
  .lookbook-btn {
    display: none !important;
  }

  .downloadArrowMobile {
    top: -4px;
    right: -85px;
  }

  #HEALTHCARE {
    background: #dbdad9e6;
  }

  .health .pin {
    flex-direction: column;
    justify-content: center;
    display: flex;
    position: relative;
    height: unset !important;
    min-height: unset !important;
  }

  .healthcare-separator {
    margin-top: unset !important;
  }

  .healthcare {
    display: none;
  }

  .healthcare-mobile-images {
    width: 100vw;
    height: 370px;
    position: relative;
  }

  .healthcare-mobile {
    width: 100vw;
    z-index: 1;
    position: absolute;
    bottom: 0;
  }

  .healthcare-mobile img {
    visibility: hidden;
    max-width: 100%;
  }

  .healthcare-mobile-images {
    display: block;
  }

  .ellipse-mask {
    display: none;
  }

  .healthcare-content {
    z-index: 2;
    background: #dbdad9e6;
    padding-top: 100px;
    padding-bottom: 60px;
    padding-right: 32px;
    position: relative !important;
  }

  .healthcare-content h1 {
    max-width: 340px;
  }

  .healthcare-content h3 {
    max-width: 390px;
  }

  .healthcare-content p {
    max-width: 410px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  #HEALTHCARE {
    background: #dbdad9e6;
  }

  .downloadArrowMobile {
    top: -4px;
    right: -85px;
  }

  .lookbook-btn {
    display: none !important;
  }

  .health .pin {
    flex-direction: column;
    justify-content: center;
    display: flex;
    position: relative;
    height: unset !important;
    min-height: unset !important;
  }

  .healthcare-separator {
    margin-top: unset !important;
  }

  .healthcare {
    display: none;
  }

  .healthcare-mobile-images {
    width: 100vw;
    height: 500px;
    position: relative;
  }

  .healthcare-mobile {
    width: 100vw;
    z-index: 1;
    position: absolute;
    bottom: 0;
  }

  .healthcare-mobile img {
    visibility: hidden;
    max-width: 100%;
  }

  .healthcare-mobile-images {
    display: block;
  }

  .ellipse-mask {
    display: none;
  }

  .healthcare-content {
    padding-top: 100px;
    padding-bottom: 60px;
    position: relative !important;
  }

  .healthcare-content h1 {
    width: 360px;
  }

  .healthcare-content h3 {
    width: 430px;
  }

  .healthcare-content p {
    width: 405px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .health .pin {
    height: 100vh;
    justify-content: center;
    display: flex;
    position: relative;
  }

  .lookbook-btn {
    display: flex !important;
  }

  .healthcare img {
    width: 1800px;
    max-width: unset !important;
  }

  .healthcare-content {
    padding-top: 100px;
  }

  .healthcare-content h1 {
    width: 510px;
  }

  .healthcare-content h3 {
    width: 540px;
  }

  .healthcare-content p {
    width: 405px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .health .pin {
    height: 100vh;
    justify-content: center;
    display: flex;
    position: relative;
  }

  .lookbook-btn {
    display: flex !important;
  }

  .healthcare img {
    width: 1800px;
    max-width: unset !important;
  }

  .healthcare-content {
    padding-top: 100px;
  }

  .healthcare-content h1 {
    width: 600px;
  }

  .healthcare-content h3 {
    width: 540px;
  }

  .healthcare-content p {
    width: 490px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .health .pin {
    height: 100vh;
    justify-content: center;
    display: flex;
    position: relative;
  }

  .lookbook-btn {
    display: flex !important;
  }

  .healthcare img {
    width: 1800px;
    max-width: unset !important;
  }

  .healthcare-content {
    padding-top: 100px;
  }

  .healthcare-content h1 {
    width: 660px;
  }

  .healthcare-content h3 {
    width: 540px;
  }

  .healthcare-content p {
    width: 670px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1799px) {
  .health .pin {
    height: 100vh;
    justify-content: center;
    display: flex;
    position: relative;
  }

  .lookbook-btn {
    display: flex !important;
  }

  .healthcare img {
    width: 1800px;
    max-width: unset !important;
  }

  .healthcare-content {
    padding-top: 100px;
  }

  .healthcare-content h1 {
    width: 660px;
  }

  .healthcare-content h3 {
    width: 540px;
  }

  .healthcare-content p {
    width: 670px;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 2399px) {
  .health .pin {
    height: 100vh;
    justify-content: center;
    display: flex;
    position: relative;
  }

  .healthcare-content {
    padding-top: 100px;
  }

  .lookbook-btn {
    display: flex !important;
  }

  .healthcare-content h1 {
    width: 660px;
  }

  .healthcare-content h3 {
    width: 540px;
  }

  .healthcare-content p {
    width: 670px;
  }
}

@media only screen and (min-width: 2400px) {
  .health .pin {
    height: 100vh;
    justify-content: center;
    display: flex;
    position: relative;
  }

  .healthcare-content {
    padding-top: 100px;
  }

  .lookbook-btn {
    display: flex !important;
  }

  .healthcare-content h1 {
    width: 660px;
  }

  .healthcare-content h3 {
    width: 540px;
  }

  .healthcare-content p {
    width: 670px;
  }
}

/*# sourceMappingURL=index.a0a7ae72.css.map */
